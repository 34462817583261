import type { TeaserFields } from "components/ui/TeaserCard";
import TeaserCard from "components/ui/TeaserCard";

export type GridTeaserElementProps = {
    fields: TeaserFields;
};

export const GridTeaserElementColumns2 = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="columns2" fields={props.fields} />;
};

export const GridTeaserElementColumns3 = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="columns3" fields={props.fields} />;
};

export const GridTeaserElementColumns4 = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="columns4" fields={props.fields} />;
};

export const GridTeaserElementImageRight = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="imageRight" fields={props.fields} />;
};

export const GridTeaserElementImageLeft = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="imageLeft" fields={props.fields} />;
};
